
import axios from "axios";

const instance = axios.create({
});

export default {
    async get(slug) {
        try {
            var response = await instance.get('/static/content/articles/'+slug+'/index.json');
            return response.data;
        }
        catch (e) {
            return null;
        }
    },
    async listByCategory(category) {
        return await this.listByTaxonomy('categories', category);
    },
    async listByTaxonomy(taxonomy, value) {
        var json = null;
        try {
            var response = await instance.get('/static/content/'+taxonomy+'/'+value+'/index.json');
            json = response.data;
        }
        catch (e) {
            return null;
        }
        if (json && json.data && json.data.items) {
            return json.data.items;
        }
        return null;
    },
}